<template>
  <ProjectCard
    title="Nieuwe groep"
    sub-title="Maak een nieuwe groep aan"
    image="/assets/img/projects/new-project-tile-bg.jpg"
  />

  <ProjectCard
    v-for="project in projects"
    :key="project.id"
    :project-id="project.id"
    :title="project.title"
    :sub-title="project.subTitle"
    :image="project.visualImage"  
  />
</template>

<script>
import { getAll } from '@/api/providers/projects';
import ProjectCard from '@/components/Projects/Card.vue';

export default {
  name: 'ProjectsIndex',

  components: {
    ProjectCard,
  },

  data: () => ({
    projects: [],
  }),

  mounted() {
    this.loadProjects();
  },

  methods: {
    loadProjects() {
      getAll()
        .then((projects) => {
          this.projects = projects
        })
        .catch((error) => { if(typeof error.default === 'function') error.default() })
    },
  },
};
</script>
